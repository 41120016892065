import React from "react";
import ReactMarkdown from "react-markdown";
import ReactWOW from "react-wow";

export default class TeamMember extends React.Component {
  render() {
    return (
      <ReactWOW delay=".1s" animation="fadeIn">
        <div className="teammember-content">
          <div className="row align-items-center content-wrapper">
            <div className="col-lg-7 col-md-12">
              <div className="content">
                <div className="mb-4">
                  <h3>{this.props.fullname}</h3>
                  <h6>{this.props.title}</h6>
                </div>

                <ReactMarkdown>{this.props.description}</ReactMarkdown>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="image">
                <img src={this.props.image} alt={this.props.fullname} />
              </div>
            </div>
          </div>
        </div>
      </ReactWOW>
    );
  }
}
