import React from "react";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import Footer from "../../components/App/Footer";
import MgtTeamMembers from "../../components/AboutUs/MgtTeamMembers";

const OurTeam = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <section className="contact-us-section contact-us-section--contact-page">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5">
                <div className="section-title">
                  <h2>Our Team</h2>
                  <p>
                    SEA Easy Capital harnesses technology to minimize the cost
                    of financial intermediation, passing direct benefits to
                    financiers and borrowers
                  </p>
                </div>
              </div>
            </div>

            {/* management team  */}
            <div className="row">
              <div className="col-12">
                <div className="section-sub-title">
                  <h4 className="underline-title">Management Team</h4>
                  <p>
                    {" "}
                    Our management team has highly experienced professionals
                    with years of experience and expertise in banking, financial
                    services and other business management areas
                  </p>
                </div>
              </div>
            </div>
            <MgtTeamMembers />

            {/* sales team  */}
            {/* <div className="row">
              <div className="col-12 pt-70">
                <div className="section-sub-title">
                  <h4 className="underline-title">Sales Team</h4>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ReactWOW delay=".1s" animation="fadeIn">
                  <div className="teammember-content">
                    <div className="row align-items-center">
                      <div className="col-lg-7 col-md-12">
                        <div className="content">
                          <div className="mb-4">
                            <h3>John Doe</h3>
                            <h6>Chief Executive Officer</h6>
                          </div>
                          <p>
                            Real innovations and a positive customer experience
                            are the heart of successful communication. Lorem
                            ipsum dolor sit amet, sectetur adipiscing elit,
                            tempor incididunt ut labore et dolore magna.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12">
                        <div className="image">
                          <img src={team_member} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactWOW>
                <ReactWOW delay=".1s" animation="fadeIn">
                  <div className="teammember-content">
                    <div className="row align-items-center">
                      <div className="col-lg-7 col-md-12">
                        <div className="content">
                          <div className="mb-4">
                            <h3>John Doe</h3>
                            <h6>Chief Executive Officer</h6>
                          </div>
                          <p>
                            Real innovations and a positive customer experience
                            are the heart of successful communication. Lorem
                            ipsum dolor sit amet, sectetur adipiscing elit,
                            tempor incididunt ut labore et dolore magna.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-12 order-lg-first">
                        <div className="image">
                          <img src={team_member} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactWOW>
              </div>
            </div> */}
          </div>
        </section>
      </div>

      <Footer />
    </Layout>
  );
};

export default OurTeam;
