import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import TeamMember from "./TeamMember";

const query = graphql`
  {
    allStrapiTeamMembers(filter: { team: { eq: "management" } }) {
      nodes {
        id
        team
        title
        full_name
        description
        image {
          publicURL
        }
      }
    }
  }
`;

const MgtTeamMembers = () => {
  const data = useStaticQuery(query);
  const {
    allStrapiTeamMembers: { nodes: tms },
  } = data;

  return (
    <div className="row">
      <div className="col-12">
        {tms.map((tm, idx) => {
          return (
            <TeamMember
              key={idx}
              title={tm.title}
              fullname={tm.full_name}
              description={tm.description}
              image={tm.image.publicURL}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MgtTeamMembers;
